export class CommentResult {
  id: number | string;
  correspondingDay: string;
  correspondingEmployee: { id: number };
  createdAt: string;
  createdBy: { id: number; firstName: string; lastName: string };
  message: string;
  type: { id: number };
  isRead: boolean;
}
